<template>
    <div>
      <b-container fluid>
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-card header-tag="header" header-bg-variant="dark">
              <template #header>
                <h5 class="mb-0" style="color: #fff">
                  <strong>Detail Stock Opname {{ nama_gudang }}</strong>
                </h5>
              </template>
              <b-row>
                <b-col cols="12" md="12" lg="12" class="mt-4">
                  <b-table
                    :items="items"
                    :fields="fields"
                    responsive
                    show-empty
                    bordered
                    striped
                    hover
                    :busy="tableBusy"
                  >
                  </b-table>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </template>
  
  <script>
  import _ from "lodash"
  export default {
    name: "list_pembelian",

    data() {
      return {
        showing: false,
        variant: "success",
        list_barang: [],
        list_batch: [],
        is_data: {
            barang_id: "",
            kode_batch: ""
        },
        msg: "",
        fields: [
          {
            key: "no",
            label: "#",
            sortDirection: "desc",
            sortable: true,
            class: "table-number text-center",
          },
  
          {
            key: "nama_barang",
            label: "Nama Barang",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          {
            key: "kode_batch",
            label: "Kode Batch",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          {
            key: "tgl_kadaluarsa",
            label: "Tgl Kadaluarsa",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          {
            key: "jumlah_stock_data",
            label: "Stock",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },
          {
            key: "jumlah_stock_real",
            label: "Stock Real",
            sortable: true,
            sortDirection: "desc",
            class: "text-left col-1",
          },
          {
            key: "selisih_stock",
            label: "Selisih",
            sortable: true,
            sortDirection: "desc",
            class: "text-left col-1",
          },
          {
            key: "keterangan_sub_stock_opname",
            label: "Keterangan",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },
        ],
        items: [],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        tableBusy: false,
      };
    },
    computed: {
        id_opname() {
            return this.$route.query ? this.$route.query.id_opname : null;
        },
        id_gudang() {
            return this.$route.query ? this.$route.query.id_gudang : null;
        },
        disabled(){
            return this.is_data.barang_id && this.is_data.kode_batch ? false : true
        },
        nama_gudang() {
            return this.$route.query ? this.$route.query.nama_gudang : null;
        },
    },
    async mounted() {
      // Set the initial number of items
      this.getDatas();
    },
    watch: {
        // perPage: function (newVal, oldVal) {
        //     if (newVal != oldVal) {
        //         this.getDatas();
        //     }
        // },
        // currentPage: function (newVal, oldVal) {
        //     if (newVal != oldVal) {
        //         this.getDatas();
        //     }
        // },
    },
    methods: {
      async getDatas() {
        let vm = this
        try {
          this.tableBusy = true;
          let ms_barang = await vm.$axios.post("/ms_barang/list", {
            halaman: 1,
            jumlah: 1000,
            });
            console.log(ms_barang, 'ini barang');
            vm.list_barang = ms_barang.data.data
          let list_stock = await vm.$axios.post("/stock_opname/list_barang_opname", {
            id : vm.id_opname,
            ms_gudang_id: vm.id_gudang
          });
          let x = list_stock.data;
          console.log(list_stock, 'ini ress')
          if (x.status === 200 && x.message === "sukses") {
            vm.items = list_stock.data.data;
            vm.items = list_stock.data.data.map((item, idx) => {
                item.no = idx + 1;
                if(!item.jumlah_stock_real){
                    item.jumlah_stock_real = 0
                }
                item.selisih_stock = item.jumlah_stock_real - item.jumlah_stock_data
                item.tgl_kadaluarsa = vm.$moment(item.tanggal_kadaluarsa).format("YYYY-MM-DD")
                return item;
            });
            console.log(vm.items, 'ini items')
            this.totalRows = vm.items.length;
          } else {
            throw x;
          }
        } catch (err) {
          if (err.message) {
            vm.$store.commit("set_alert", {variant: "danger", msg: _.toUpper(err.message), showing: true});
          } else {
            vm.$store.commit("set_alert", {variant: "danger", msg: _.toUpper(err), showing: true});
          }
        } finally {
          this.tableBusy = false;
        }
      },
      onChildClicked(action, data) {
        // console.log(data, 'ini datanyaaaa');
            this.$router.push({
                path: action,
                query: { id : data.item.item.pembelian_id },
            });
        },

        rowClass(item, type) {
            if (!item || type !== 'row') return
            console.log(item.kadaluarsanya, 'itikiw');
            if (item.kadaluarsa3bln == true){
              return 'table-danger'
            }else if(item.kadaluarsa6bln == true){
              return 'table-warning'
            }
        },
      triggerAlert(event) {
        let vm = this;
        // console.log('ihiyyyyyyyyyyyy');
        vm.$store.commit("set_alert", event);
        vm.getDatas()
      },
    },
  };
  </script>